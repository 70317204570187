/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2020] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {StationEventType} from '../helpers/common';
import {DateTime} from 'luxon';

export function prependPath(object: any, path?: string) {
    Object.keys(object).forEach((key) => {
        if (typeof object[key] === 'object' && 'path' in object[key]) {
            let prependedPath = (path ?? '') + '/' + object[key].path;
            object[key].path = prependedPath;
            prependPath(object[key], prependedPath);
        }
    });
}

export const API = {
    path: '/api',
    VERSION: {
        path: 'version',
    },
    LOGIN: {
        path: 'login',
    },
    LOGOUT:{
        path: 'logout',
    },
    STAFF_LOGIN: {
        path: 'staff-login',
    },
    STAFF_LOGOUT: {
        path: 'staff-logout',
    },
    GET_STAFF_LOGOUT_SUMMARY: {
        path: 'staff-logout-summary',
        response: {} as StaffLogoutSummaryResponse,
    },
    GET_STATION_INFO: {
        path: 'get-station-info',
        response: {} as StationInfoResponse,
    },
    GET_NAV_ACCESS: {
        path: 'get-nav-access'
    },
    GET_PROFILE_INFO: {
        path: 'get-profile-info'
    },
    USER: {
        path: 'user',
        SAVE_SETTINGS: {
            path: 'save-settings'
        }
    },
    APP_CONFIG: {
        path: 'app-config'
    },
    JOBS: {
        path: 'jobs',
        response: {} as Paginated<Job>,
        JOB: {
            path: ':jobNumber',
            response: {} as JobGetResponse,
            CONSUME: {
                path: 'consume'
            },
            TASKS: {
                path: 'tasks',
                COMPLETE: {
                    path: 'complete'
                },
                COMPLETE_ALL: {
                    path: 'complete-all'
                },
            }
        }
    },
    PARTS: {
        path: 'parts',
        PART: {
            path: ':partId',
            response: {} as PartGetResponse,
            TASKS: {
                path: 'tasks',
                COMPLETE: {
                    path: 'complete',
                },
            },
        },
    },
    REWORKS: {
        path: 'reworks',
        REQUESTS: {
            path: 'requests',
            response: {} as ReworksRequestsGetResponse,
            REQUEST: {
                path: ':reworkRequestId'
            },
            REASONS: {
                path: 'reasons',
                response: [] as ReworksRequestsReasonsGetResponse,
                REASON: {
                    path: ':reasonId'
                }
            },
            DECLINE: {
                path: 'decline'
            },
        },
        GENERATE: {
            path: 'generate',
        },
    },
    STAFF: {
        path: 'staff',
        response: {} as StaffGetResponse,
        CLOCK_OUT: {
            path: 'clock-out'
        },
        SET_PASSWORD: {
            path: 'set-password'
        },
        TIMESHEETS: {
            path: 'timesheets',
            response: {} as TimesheetsGetResponse,
            EXPORT: {
                path: 'export',
                response: {}
            }
        },
    },
    STATIONS: {
        path: 'stations',
        response: {} as StationsResponse,
        TOKEN: {
            path: 'token',
            response: {} as Token
        },
        STAFF: {
            path: 'staff',
            response: [] as string[],
        },
        JOBS: {
            path: 'jobs',
            response: {} as StationJobsGetResponse,
            JOB: {
                path: ':jobNumber',
                response: {} as StationJobGetResponse
            }
        }
    },
    STATION_EVENTS: {
        path: 'station-events',
    },
    init: function() {
        prependPath(this, this.path);
        return this;
    }
}.init();

export enum Role {
    OPERATOR = 'ROLE_OPERATOR', USER = 'ROLE_USER', TEAM_LEADER = 'ROLE_TEAMLEADER', ADMIN = 'ROLE_ADMIN'
}

export enum CompletionStatus {
    TO_DO = 'TO DO', IN_PROGRESS = 'IN PROGRESS', OVERDUE = 'OVERDUE', DONE = 'DONE',
}

export enum PartTaskStatus {
    INCOMPLETE, COMPLETE,
}

export interface Paginated<T> {
    metadata: {
        page: number,
        limit: number,
        totalResults: number,
        totalPages: number,
    },
    results: T[]
}

export interface Job {
    jobNumber: string,
    client: string,
    cabinets: number,
    parts: number,
    latestTaskCompleted?: string,
    latestTaskCompletedTime?: string,
    latestTaskStarted?: string,
    latestTaskStartedTime?: string,
    latestClockInTime?: string,
    status: CompletionStatus,
    createdAt: string,
    factoryDueDate: string,
}

interface JobGetResponse {
    jobNumber: string,
    client: string,
    cabinetCount: number,
    partCount: number,
    status: CompletionStatus,
    createdAt: Date,
    factoryDueDate: Date,
    consumed: boolean,
    cabinets: {
        cabId: string
        parts: {
            id: number,
            ref: string,
            name: string,
            run: string,
            board: string,
            pendingRework: boolean,
            reworked: boolean,
            reworkIndex: number | null,
            tasks: {
                [key: string]: PartTaskStatus,
            }
        }[]
    }[],
    taskMap: {[key: string]: string}
}

interface PartGetResponse {
    id: number,
    name: string,
    quantity: number,
    length: number,
    width: number,
    board: string,
    patternNumber: number,
    taskTimestamps: {
        [key: string]: string
    }
}

interface ReworksRequestsGetResponse {
    content: ReworkRequest[],
    metadata: {
        reasons: ReworkRequestReason[]
    },
}

export interface ReworkRequest {
    id: number,
    partReference: string,
    jobNumber: string,
    staffUsername: string,
    staffName: string,
    created: string,
    stationName: string,
    stationLongName: string,
    reason: string,
    comment: string,
    lastGeneratedBy: string,
}

export interface ReworkRequestReason {
    id: number,
    name: string,
    redesign: boolean,
}

type ReworksRequestsReasonsGetResponse = ReworkRequestReason[]

export interface StationInfoResponse {
    id: number,
    code: string,
    name: string,
    inactive: Date | null,
    shared: boolean,
    taskId: number,
    taskShortName: string,
    taskLongName: string,
    mccSupported: boolean,
}

export interface StationJobsGetResponse {
    jobs: StationJob[]
    currentlyOpenEvent: {
        eventType: StationEventType,
        startTime: DateTime,
        job?: StationJob,
        prevJob?: StationJob,
    },
}

export interface StationJob {
    id: number,
    jobNumber: string,
    client: string,
    status: CompletionStatus,
    createdAt: string,
    factoryDueDate: string,
    cabinetTaskCount: number,
    totalPartTaskCount: number,
    completedPartTaskCount: number,
    budgetedSeconds: number,
    workedSeconds: number,
}

export interface StationJobDetails {
    id: number,
    jobNumber: string,
    client: string,
    status: CompletionStatus,
    createdAt: string,
    factoryDueDate: string,
    cabinetTaskCount: number,
    totalPartTaskCount: number,
    completedPartTaskCount: number,
    parts: StationJobPart[],
    budgetedSeconds: number,
    workedSeconds: number,
}

type StationJobGetResponse = StationJobDetails

export interface StationJobPart {
    id: number,
    reference: string,
    name: string,
    cabId: number,
    run: string,
    board: string,
    complete: boolean,
}

interface StaffResponse {
    [id: number]: {
        id: number,
        name: string,
        permittedTasks: number[],
        inactive: boolean,
    }
}

interface StaffGetResponse {
    staff: StaffResponse,
    tasks: { [id: number]: { id: number, name: string, longName: string } }
    pageMetadata: {
        totalPages: number,
    }
}

export interface WorklogEntry {
    id: number
    name: string
    jobNumber?: string
    station?: string
    startTime: Date
    endTime: Date
    rawStartTime: Date
    rawEndTime: Date
    preciseStartTime: Date
    preciseEndTime: Date
    modifiedStartTime: Date
    modifiedEndTime: Date
    inProgress?: boolean
    beforeStart?: WorklogEntry
    afterEnd?: WorklogEntry
    afterStart?: WorklogEntry
    beforeEnd?: WorklogEntry
    modified?: boolean
}

export interface Worklog {
    name: string
    editable: boolean
    editing: boolean
    entries: WorklogEntry[]
    verified: boolean
}

export interface Timesheet {
    timesheetId: number
    staffId: number
    staffUsername: string
    notes?: string
    worklogs: { [key: string]: Worklog }
    verified: boolean
    inProgress: boolean
}

export interface WorklogType { name: string, threshold?: number }

export interface TimesheetsGetResponse {
    timesheets: { [key: string]: Timesheet },
    worklogTypes: WorklogType[],
}

// Non-DTO server objects.
// TODO: Convert to DTOs

interface StaffLogoutSummaryResponse {
    fullName: string,
    timesheetEvents: StaffLogoutSummaryResponseItem[],
}

interface StaffLogoutSummaryResponseItem {
    eventName: string,
    eventDurationString: string,
}

export type Token = {
    id: number
    name: string,
    token: string,
}

export type Station = {
    id: number,
    code: string,
    name: string,
    active: boolean,
    shared: boolean,
    insightToken?: string,
    task: number,
    tokens: Token[],
    modified: boolean,
}

export type Task = { id: number, name: string, longName: string }

interface StationsResponse {
    stations: { [key: number]: Station },
    tasks: { [key: number]: Task },
}
