/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

// @flow
import React, {Component, Suspense} from 'react';
import {connect} from 'react-redux';

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Footer = React.lazy(() => import('../components/Footer'));

const loading = () => <div className='text-center'></div>;

type DefaultLayoutProps = {
    children?: any,
};

class DefaultLayout extends Component<DefaultLayoutProps> {
    // TODO: Replace background with new Greenmount specific image.
    // /**
    //  * On component update - update layout
    //  */
    // componentDidMount = () => {
    //     if (document.body) document.body.classList.add('authentication-bg');
    // };

    // /**
    //  * On component unmount - reset layout
    //  */
    // componentWillUnmount = () => {
    //     if (document.body) document.body.classList.remove('authentication-bg');
    // };

    render() {
        const children = this.props.children || null;

        return <>
            <Suspense fallback={loading()}>{children}</Suspense>

            <Suspense fallback={loading()}>
                <Footer {...this.props} leftNode={<></>} middleNode={<></>} fullWidth={true}/>
            </Suspense>
        </>;
    }
}

export default connect()(DefaultLayout);
