/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2020] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import React from 'react';
import {Route} from 'react-router';
import {prependPath, Role} from './api';

export interface PageType {
    displayName: string,
    path: string,
    access?: string,
    icon?: string,
}

export const pageRoute = (page: any, component: React.ReactNode, route: Route, exact?: boolean) => {
    // TODO: Correctly type `PAGES`.
    let pageAsPageType = page as PageType
    return {
        path: pageAsPageType.path,
        exact: exact,
        name: pageAsPageType.displayName,
        access: pageAsPageType.access,
        icon: pageAsPageType.icon,
        component: component,
        route: route,
    }
}

export const PAGES = {
    path: '',
    displayName: 'Not Found',
    // error
    ERROR_PAGE: {
        displayName: 'Error',
        path: 'error',
    },
    // apps
    DASHBOARD: {
        displayName: 'Dashboard',
        path: 'dashboard',
        access: Role.USER,
        icon: 'uil-home-alt',
    },
    JOBS: {
        displayName: 'Jobs List',
        path: 'jobs',
        access: Role.USER,
        icon: 'mdi mdi-briefcase',
    },
    REWORKS: {
        displayName: 'Reworks',
        path: 'reworks',
        access: Role.ADMIN,
        icon: 'mdi mdi-restart'
    },
    STAFF: {
        displayName: 'Staff',
        path: 'staff',
        access: Role.ADMIN,
        icon: 'mdi mdi-account',
    },
    REVIEW: {
        displayName: 'Timesheets',
        path: 'timesheets',
        access: Role.TEAM_LEADER,
        icon: 'mdi mdi-timetable',
    },
    STATIONS: {
        displayName: 'Stations',
        path: 'stations',
        access: Role.ADMIN,
        // TODO: Change back to 'material-icons(precision_manufacturing)' when installed locally
        icon: 'mdi mdi-desktop-classic',
    },
    // auth
    LOGIN: {
        displayName: 'Login',
        path: 'login',
    },
    LOGOUT: {
        displayName: 'Logout',
        path: 'logout',
    },
    // terminal
    TERMINAL: {
        displayName: '',
        path: 'terminal',
        LOGIN: {
            displayName: 'Login',
            path: 'login',
        },
        LOGOUT_SUMMARY: {
            displayName: 'Logout Summary',
            path: 'logout-summary',
        },
        JOB_TASKS: {
            displayName: 'Jobs List',
            access: Role.OPERATOR,
            path: 'jobs',
        },
    },
    // user
    INITIALISE: {
        displayName: '',
        path: 'initialise'
    },
    SETTINGS: {
        displayName: 'My Account',
        path: 'user-settings',
    },
    init: function() {
        prependPath(this, this.path);
        return this;
    }
}.init();

export const MOTIONDESIGN = {
    displayName: 'Motion Design',
    path: 'https://motiondesign.nz',
}

export const SUPPORT = {
    displayName: 'Contact Support',
    path: 'https://motiondesign.atlassian.net/servicedesk/customer/portal/14',
}

export const INSIGHT = {
    displayName: 'Insight',
    path: 'https://greenmount.myinsights.nz'
}
