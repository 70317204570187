/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
import {
    CHANGE_ACTIVE_MENU_FROM_LOCATION,
    CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS,
    INIT_MENU,
    INIT_MENU_FAILED,
    INIT_MENU_SUCCESS,
} from './constants';

type Action = { type: string, payload: {} };
type State = { +value: boolean };

const AppMenu = (state: State = {}, action: Action) => {
    switch (action.type) {
        case INIT_MENU:
            return action.payload;
        case INIT_MENU_SUCCESS:
            return {...state, ...action.payload};
        case INIT_MENU_FAILED:
            return {...state, error: action.payload};
        case CHANGE_ACTIVE_MENU_FROM_LOCATION:
            return {...state};
        case CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS:
            return {...state, ...action.payload};
        default:
            return state;
    }
};

export default AppMenu;
