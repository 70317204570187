/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
import React, {Component} from 'react';
import {DateTime} from 'luxon';
import {Col, Row} from 'reactstrap';

interface LoginClockProps {
    timeComponent: (time: string) => React.ReactNode,
    dateComponent: (date: string) => React.ReactNode,
    center?: boolean,
}

interface LoginClockState {
    date: DateTime,
}

/**
 * Used to display the current date/time.
 */
export default class LoginClock extends Component<LoginClockProps, LoginClockState> {
    private intervalId: ReturnType<typeof setTimeout> | undefined
    constructor(props: LoginClockProps) {
        super(props)
        this.state = {
            date: DateTime.now(),
        }
    }

    componentDidMount() {
        this.intervalId = setTimeout(
            () => this.clockTick(), 1000
        )
    }

    componentWillUnmount() {
        if (this.intervalId) clearInterval(this.intervalId)
    }

    /**
     * Set new date per tick
     */
    clockTick() {
        this.setState({
                date: DateTime.now(),
            }
        )
    }

    /**
     * Get the resized time to display.
     */
    getTime() {
        return this.props.timeComponent(DateTime.fromMillis(this.state.date.valueOf()).toFormat('HH:mm'))
    }

    /**
     * Gets the resized date to display.
     */
    getDate() {
        return this.props.dateComponent(DateTime.fromMillis(this.state.date.valueOf()).toFormat('EEEE, MMMM d'))
    }

    render() {
        return (
            <React.Fragment>
                {this.props.center ?
                    <Row className='flex-column text-center'>
                        <Col>{this.getTime()}</Col>
                        <Col>{this.getDate()}</Col>
                    </Row>
                    :
                    <Row className='align-items-center'>
                        <Col style={{'flexGrow': 0, 'flexBasis': 'content'}}>
                                 {this.getTime()}
                        </Col>
                        <Col className='p-0'>{this.getDate()}</Col>
                    </Row>
                }
            </React.Fragment>
        )
    }
}
