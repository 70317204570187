/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
export const INIT_MENU = 'INIT_MENU';
export const INIT_MENU_SUCCESS = 'INIT_MENU_SUCCESS';
export const INIT_MENU_FAILED = 'INIT_MENU_FAILED';
export const CHANGE_ACTIVE_MENU_FROM_LOCATION = 'CHANGE_ACTIVE_MENU_FROM_LOCATION';
export const CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS = 'CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS';