/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

export const LOCAL_STORAGE_STATION_TOKEN = 'GI_FA.station_token';

/**
 * Returns the station token the client is assigned, or null if it isn't assigned one.
 */
const getToken = () => {
    return localStorage.getItem(LOCAL_STORAGE_STATION_TOKEN);
};

/**
 * Sets the station token to the given string. If null, remove the token.
 */
const setToken = (value: string | null): void => {
    if (value == null) localStorage.removeItem(LOCAL_STORAGE_STATION_TOKEN)
    else localStorage.setItem(LOCAL_STORAGE_STATION_TOKEN, value);
}

export {getToken, setToken};
