/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

// @flow
import React, {Component, Suspense} from 'react';
import {Container} from 'reactstrap';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {StationType} from '../redux/station/types';
import ClockComponent from '../components/terminal/LoginClock'

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import('../components/terminal/TerminalTopbar'));
const Footer = React.lazy(() => import('../components/Footer'));
const Sidebar = React.lazy(() => import('../components/terminal/TerminalSidebar'));

type TerminalLayoutProps = {
    changeLayout: PropTypes.func,
    children?: any,
    station: StationType
};

type TerminalLayoutState = {
    isLandscape: boolean
};

// loading
const emptyLoading = () => <div></div>;
const loading = () => <div className='text-center'></div>;

/**
 * Layout for the terminal login page.
 * This is based off of Vertical.js
 */
class TerminalLayout extends Component<TerminalLayoutProps, TerminalLayoutState> {
    constructor(props) {
        super(props);
        this.state = {
            isLandscape: true
        }
    }

    /**
     * Handles the menu on window resizes
     */
    updateDimensions = () => {
        // activate the condensed sidebar if smaller devices like ipad or tablet
        if (window.innerWidth >= 768 && window.innerWidth <= 1028) {
            this.setState({isLandscape: false})
        } else if(window.innerWidth > 1028) {
            this.setState({isLandscape: true})
        }
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.updateDimensions.bind(this));
        this.updateDimensions()
    };

    /**
     * Removes the listener
     */
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions.bind(this));
    }

    render() {
        return (
            <div className='app'>
                <div className='wrapper'>
                    <div className={'content-page ' + (this.state.isLandscape ? 'content-page-terminal-landscape' : 'content-page-terminal-portrait')}>
                        <div className='content'>
                            <Suspense fallback={emptyLoading()}>
                                <Topbar {...this.props} 
                                    stationName={this.props.station?.longName} 
                                    setScannedQrCode={this.setScannedQrCode}/>
                            </Suspense>
                            <Container fluid>
                                <Suspense fallback={loading()}>
                                    <div className={'pt-3 terminal-layout-content-' + (this.state.isLandscape ? 'landscape' : 'portrait')}>
                                        {this.props.children}
                                    </div>
                                </Suspense>
                            </Container>
                        </div>
                        <Suspense fallback={emptyLoading()}>
                            <Footer {...this.props} leftNode={
                                <ClockComponent
                                    timeComponent={time => <h4 className={'float-right m-0'}>{time}</h4>}
                                    dateComponent={date => <h5 className={'float-left m-0 font-weight-normal'}>{date}</h5>}
                                    center={false}
                                />
                            }/>
                        </Suspense>
                    </div>

                    <Suspense fallback={emptyLoading()}>
                        <Sidebar
                            {...this.props}
                            station={this.props.station}
                            isHorizontal={!this.state.isLandscape}
                        />
                    </Suspense>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        station: state.Station.data,
        layout: state.Layout,
    };
};
export default connect(
    mapStateToProps,
)(TerminalLayout);
