/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
import {setAuthenticated} from './authUtils'

function getCookie(name: string): string | null {
    const nameLenPlus = (name.length + 1);
    return document.cookie
        .split(';')
        .map(c => c.trim())
        .filter(cookie => cookie.substring(0, nameLenPlus) === `${name}=`)
        .map(cookie => decodeURIComponent(cookie.substring(nameLenPlus)))[0] || null;
}

const parseResponse = (response: Response) => {
    const contentDisposition = response.headers.get('Content-Disposition')
    const contentType = response.headers.get('content-type')
    
    if (contentDisposition && contentDisposition.startsWith('attachment')) {
        // Response is file.
        const fileName = contentDisposition.split('filename=')[1];
        return response.blob()
            .then(blob => {
                const a = document.createElement('a')
                // Trim surrounding quotes from filename to prevent
                // downloading "_filename.file_" on Google Chrome.
                a.download = fileName.replace(/^"+(.*)"+$/, '$1')
                a.href = URL.createObjectURL(blob)
                document.body.appendChild(a)
                a.click()
                a.remove()
            })
    } else if (contentType && contentType.indexOf('application/json') !== -1) {
        // Response is JSON.
        return response.json()
    } else {
        // Response is text.
        return response.text()
    }
}

const getPromiseForGoLogin = (url: string, options: any = {}, goLoginOn401: boolean = true) => {
    return fetch(url, options)
        .then(response => {
            if (!response.ok) throw response;
            return parseResponse(response)
        })
        .catch(error => {
            if (goLoginOn401 && error.status === 401) {
                setAuthenticated(false)
                window.location.reload()
            }
            throw error;
        });
}

/**
 * Delete request (method=delete).
 * NOTE: delete requests does not accept data in the body. Need to add param in the url.
 */
export const deleteOrGoLogin = (url: string, goLoginOn401: boolean = true) => {
    let options = {
        method: 'DELETE',
        headers: {
            'credentials': 'same-origin',
            'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        },
    };

    return getPromiseForGoLogin(url, options, goLoginOn401)
};

export const postOrGoLogin = (url: string, json: any, goLoginOn401: boolean = true) => {
    let options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'credentials': 'same-origin',
            'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        },
        body: JSON.stringify(json)
    };

    return getPromiseForGoLogin(url, options, goLoginOn401);
};

export const patchOrGoLogin = (url: string, json: any, goLoginOn401: boolean = true) => {
    let options = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'credentials': 'same-origin',
            'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        },
        body: JSON.stringify(json)
    };

    return getPromiseForGoLogin(url, options, goLoginOn401)
};

export const getOrGoLogin = (url: string, fields?: any, goLoginOn401: boolean = true) => {
    let options: any = {}
    options['method'] = 'GET'
    if (fields) {
        url = url + '?' + new URLSearchParams(fields);
    }

    return getPromiseForGoLogin(url, options, goLoginOn401)
};
