/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {
    CLEAR_OPEN_EVENT,
    INIT_STATION_JOB_DETAILS,
    INIT_STATION_JOB_DETAILS_FAILED,
    INIT_STATION_JOB_DETAILS_SUCCESS,
    INIT_STATION_JOBS,
    INIT_STATION_JOBS_FAILED,
    INIT_STATION_JOBS_SUCCESS,
    SET_DISABLE_BUTTONS,
    SET_DISABLE_QR_CODE_FOCUS,
    SET_IS_MODAL_OPEN,
    SET_JOB_NUMBER_FILTER,
    SET_PROCESSING,
    SET_RUNNING_EVENT_TYPE,
    SET_SCANNED_QR_CODE,
    SET_SELECTED_JOB
} from './constants';
import {TerminalAction} from './types';
import {API, StationJob, StationJobDetails} from '../../constants/api';
import {StationEventType} from '../../helpers/common';

export const setScannedQrCode = (scannedQrCode: string | null): TerminalAction => ({
    type: SET_SCANNED_QR_CODE,
    payload: scannedQrCode,
})

export const setIsModalOpen = (isModalOpen: boolean): TerminalAction => ({
    type: SET_IS_MODAL_OPEN,
    payload: isModalOpen,
});

export const setDisableButtons = (disableButtons: boolean): TerminalAction => ({
    type: SET_DISABLE_BUTTONS,
    payload: disableButtons,
})

export const setDisableQrCodeFocus = (disableQrCodeFocus: boolean): TerminalAction => ({
    type: SET_DISABLE_QR_CODE_FOCUS,
    payload: disableQrCodeFocus,
})

export const setProcessing = (processing: boolean): TerminalAction => ({
    type: SET_PROCESSING,
    payload: processing
})

export const setRunningEventType = (stationEventType: StationEventType | null): TerminalAction => ({
    type: SET_RUNNING_EVENT_TYPE,
    payload: stationEventType,
})

export const clearOpenEvent = (): TerminalAction => ({
    type: CLEAR_OPEN_EVENT,
    payload: {}
})

export const initStationJobs = (jobNumberFilter?: string): TerminalAction => ({
    type: INIT_STATION_JOBS,
    payload: {jobNumberFilter},
});

export const initStationJobsSuccess = (response: typeof API.STATIONS.JOBS.response): TerminalAction => ({
    type: INIT_STATION_JOBS_SUCCESS,
    payload: response,
});

export const initStationJobsFailed = (error: any): TerminalAction => ({
    type: INIT_STATION_JOBS_FAILED,
    payload: error,
});

export const initStationJobDetails = (jobNumber: string): TerminalAction => ({
    type: INIT_STATION_JOB_DETAILS,
    payload: {jobNumber},
});

export const initStationJobDetailsSuccess = (stationJobDetails: StationJobDetails): TerminalAction => ({
    type: INIT_STATION_JOB_DETAILS_SUCCESS,
    payload: stationJobDetails,
});

export const initStationJobDetailsFailed = (error: any): TerminalAction => ({
    type: INIT_STATION_JOB_DETAILS_FAILED,
    payload: error,
});

export const setRunningJob = (job: StationJob | null): TerminalAction => ({
    type: SET_SELECTED_JOB,
    payload: job,
})

export const setJobNumberFilter = (jobNumberFilterInput: string): TerminalAction => ({
    type: SET_JOB_NUMBER_FILTER,
    payload: jobNumberFilterInput,
})
