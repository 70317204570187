/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

// @flow
import {
    CHANGE_ACTIVE_MENU_FROM_LOCATION,
    CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS,
    INIT_MENU,
    INIT_MENU_FAILED,
    INIT_MENU_SUCCESS,
} from './constants';

type DefaultMenuAction = { type: string, payload: {} };

export const initMenu = (): DefaultMenuAction => ({
    type: INIT_MENU,
    payload: {},
});

export const initMenuSuccess = (menuItems: Array<{}>): DefaultMenuAction => ({
    type: INIT_MENU_SUCCESS,
    payload: { menuItems },
});

export const changeActiveMenuFromLocation = (): DefaultMenuAction => ({
    type: CHANGE_ACTIVE_MENU_FROM_LOCATION,
    payload: {},
});

export const changeActiveMenuFromLocationSuccess = (activatedMenuItemIds: Array<string>): DefaultMenuAction => ({
    type: CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS,
    payload: {activatedMenuItemIds},
});

export const initMenuFailed = (message: string): DefaultMenuAction => ({
    type: INIT_MENU_FAILED,
    payload: {message},
});
