/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';

import {
    initStationJobDetailsFailed,
    initStationJobDetailsSuccess,
    initStationJobsFailed,
    initStationJobsSuccess,
} from './actions';
import {INIT_STATION_JOB_DETAILS, INIT_STATION_JOBS} from './constants';
import {getOrGoLogin} from '../../helpers/api';
import {API, StationJobsGetResponse} from '../../constants/api';
import {TerminalJobTasksType, TerminalJobTaskType} from './types';

function* initStationJobs({payload: {jobNumberFilter}} : TerminalJobTasksType) {
    try {
        let params = jobNumberFilter ? `?search=${jobNumberFilter}` : ''
        const response: StationJobsGetResponse =
            yield call(getOrGoLogin, API.STATIONS.JOBS.path + params)
        if (jobNumberFilter) {
            // If an earlier request was sent, do not use the response of that.
            const getSearch = (state: any) => state.Terminal.jobNumberFilter
            let currentSearch: String | null | undefined = yield select(getSearch)
            if (currentSearch !== jobNumberFilter) {
                return
            }
        }
        yield put(initStationJobsSuccess(response));
    } catch (error: any) {
        yield put(initStationJobsFailed(error));
    }
}

function* initStationJobDetails({payload: {jobNumber}}: TerminalJobTaskType) {
    try {
        const response: typeof API.STATIONS.JOBS.JOB.response =
            yield call(getOrGoLogin, API.STATIONS.JOBS.JOB.path.replace(':jobNumber', jobNumber.toString()));
        yield put(initStationJobDetailsSuccess(response));
    } catch (error: any) {
        yield put(initStationJobDetailsFailed(error));
    }
}

export function* watchStationJobsInit(): any {
    // @ts-ignore
    yield takeEvery(INIT_STATION_JOBS, initStationJobs);
}

export function* watchStationJobDetailsInit(): any {
    // @ts-ignore
    yield takeEvery(INIT_STATION_JOB_DETAILS, initStationJobDetails);
}

function* terminalSaga(): any {
    yield all([
        fork(watchStationJobsInit),
        fork(watchStationJobDetailsInit),
    ]);
}

export default terminalSaga;
