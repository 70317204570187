/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {
    CLEAR_OPEN_EVENT,
    INIT_STATION_JOB_DETAILS,
    INIT_STATION_JOB_DETAILS_FAILED,
    INIT_STATION_JOB_DETAILS_SUCCESS,
    INIT_STATION_JOBS,
    INIT_STATION_JOBS_FAILED,
    INIT_STATION_JOBS_SUCCESS,
    SET_DISABLE_BUTTONS,
    SET_DISABLE_QR_CODE_FOCUS,
    SET_IS_MODAL_OPEN,
    SET_JOB_NUMBER_FILTER,
    SET_PROCESSING,
    SET_RUNNING_EVENT_TYPE,
    SET_SCANNED_QR_CODE,
    SET_SELECTED_JOB,
} from './constants';
import {State, TerminalAction} from './types';
import {API} from '../../constants/api';
import {DateTime} from 'luxon';

const INIT_STATE = {
    qrCode: null,
    error: null,
    isModalOpen: false,
    disableButtons: true,
    disableQrCodeFocus: false,
    stationJobs: null,
    stationJobDetails: null,
    loading: false,
    openEvent: null,
    processingJobTask: false,
    runningJob: null,
    jobNumberFilter: '',
    modalInSidebar: null,
};

const Terminal = (state: State = INIT_STATE, action: TerminalAction) => {
    switch (action.type) {
        case SET_SCANNED_QR_CODE:
            return {...state, lastScannedQrCode: action.payload};
        case SET_IS_MODAL_OPEN:
            return {...state, isModalOpen: action.payload};
        case SET_DISABLE_BUTTONS:
            return {...state, disableButtons: action.payload};
        case SET_DISABLE_QR_CODE_FOCUS:
            return {...state, disableQrCodeFocus: action.payload};
        case SET_PROCESSING:
            return {...state, processingJobTask: action.payload};
            
        case INIT_STATION_JOBS:
            return {
                ...state, 
                loading: true,
                error: null,
                stationJobs: null,
                stationJobParts: null,
                disableButtons: true
            };
        case INIT_STATION_JOBS_SUCCESS: {
            const jobsPayload = (action.payload as typeof API.STATIONS.JOBS.response)
            return {
                ...state,
                loading: false,
                stationJobs: jobsPayload.jobs,
                openEvent: jobsPayload.currentlyOpenEvent ? {
                    eventType: jobsPayload.currentlyOpenEvent.eventType,
                    startTime: DateTime.fromISO(jobsPayload.currentlyOpenEvent.startTime.toString()),
                    job: jobsPayload.currentlyOpenEvent.job
                } : null,
                lastJobBeforeBreak: jobsPayload.currentlyOpenEvent?.prevJob,
                disableButtons: false,
            };
        }
        case INIT_STATION_JOBS_FAILED:
            return {...state, error: action.payload, disableButtons: false};

        case CLEAR_OPEN_EVENT:
            return {...state, openEvent: null}

        case INIT_STATION_JOB_DETAILS:
            return {
                ...state,
                loading: true,
                error: null,
                stationJobDetails: null
            };
        case INIT_STATION_JOB_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                stationJobDetails: action.payload
            };
        case INIT_STATION_JOB_DETAILS_FAILED:
            return {...state, error: action.payload};

        case SET_SELECTED_JOB:
            return {...state, runningJob: action.payload}
        case SET_JOB_NUMBER_FILTER:
            return {...state, jobNumberFilter: action.payload}
        case SET_RUNNING_EVENT_TYPE:
            return {...state, runningEventType: action.payload}
        default:
            return {...state};
    }
};

export default Terminal;
