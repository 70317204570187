/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {Redirect, Route} from 'react-router-dom';
import {isUserAuthenticated} from '../helpers/authUtils';
import {PAGES} from '../constants/pages';
import React from 'react';
import {renderErrorAsPage} from '../helpers/errors';
import {getToken} from '../helpers/stationUtils';

/**
 * Secures pages that requires a user to be logged in and have page access.
 */
export const PrivateRoute = ({component: Component, access, user, path, ...rest}) => (
    <Route
        {...rest}
        render={props => {
            // Check if user is logged in.
            if (!isUserAuthenticated()) {
                // Not logged in so redirect to login.
                if (getToken()) {
                    return <Redirect to={{pathname: PAGES.TERMINAL.LOGIN.path, state: {from: props.location}}}/>;
                } else {
                    return <Redirect to={{pathname: PAGES.LOGIN.path, state: {from: props.location}}}/>;
                }
            }

            if (path !== PAGES.INITIALISE.path) {
                // Check if user is initialised and if page requires authorisation.
                if (!user) {
                    // User not initialised so cannot authorise. Wait for initialisation with return url.
                    return <Redirect to={{pathname: PAGES.INITIALISE.path, state: {from: props.location}}}/>;
                } else if (access && !user.authorities.some(authority => authority === access)) {
                    // User not authorised for page so redirect to error page.
                    return renderErrorAsPage(
                        new Response(null, {'status': 403, 'statusText': 'Forbidden'}),
                        `Require ${access} authority to access this page`,
                    )
                }
            }

            // Logged in and authorised, so return component.
            return <Component {...props} user={user}/>;
        }}
    />
);
