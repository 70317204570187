/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
export const SET_SCANNED_QR_CODE = 'SET_SCANNED_QR_CODE';
export const SET_IS_MODAL_OPEN = 'SET_MODAL_OPEN';
export const SET_DISABLE_BUTTONS = 'SET_DISABLE_BUTTONS';
export const SET_DISABLE_QR_CODE_FOCUS = 'SET_DISABLE_QR_CODE_FOCUS'
export const SET_PROCESSING = 'SET_PROCESSING_'
export const INIT_STATION_JOBS = 'INIT_STATION_JOBS'
export const INIT_STATION_JOBS_SUCCESS = 'INIT_STATION_JOBS_SUCCESS';
export const INIT_STATION_JOBS_FAILED = 'INIT_STATION_JOBS_FAILED';
export const CLEAR_OPEN_EVENT = 'CLEAR_OPEN_EVENT';
export const INIT_STATION_JOB_DETAILS = 'INIT_STATION_JOB_PARTS';
export const INIT_STATION_JOB_DETAILS_SUCCESS = 'INIT_STATION_JOB_PARTS_SUCCESS';
export const INIT_STATION_JOB_DETAILS_FAILED = 'INIT_STATION_JOB_PARTS_FAILED';
export const SET_SELECTED_JOB = 'SET_SELECTED_JOB';
export const SET_JOB_NUMBER_FILTER = 'SET_JOB_NUMBER_FILTER';
export const SET_RUNNING_EVENT_TYPE = 'SET_RUNNING_EVENT_TYPE';
