/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

// @flow
import {
    CHANGE_LAYOUT,
    CHANGE_LAYOUT_WIDTH,
    CHANGE_SIDEBAR_THEME,
    CHANGE_SIDEBAR_TYPE,
    HIDE_RIGHT_SIDEBAR,
    SHOW_RIGHT_SIDEBAR,
    TOGGLE_RIGHT_SIDEBAR,
} from './constants';

import * as layoutConstants from '../../constants/layout';

const INIT_STATE = {
    layoutType: layoutConstants.LAYOUT_VERTICAL,
    layoutWidth: layoutConstants.LAYOUT_WIDTH_FLUID,
    leftSideBarTheme: layoutConstants.LEFT_SIDEBAR_THEME_DEFAULT,
    leftSideBarType: layoutConstants.LEFT_SIDEBAR_TYPE_FIXED,
    showRightSidebar: false,
};

type LayoutAction = { type: string, payload?: any };
type State = { 
    showRightSidebar?: boolean, +value?: boolean,
    rightSidebarTitle: string,
    rightSidebarChildren: any,
};

const Layout = (state: State = INIT_STATE, action: LayoutAction) => {
    switch (action.type) {
        case CHANGE_LAYOUT:
            return {
                ...state,
                layoutType: action.payload,
            };
        case CHANGE_LAYOUT_WIDTH:
            return {
                ...state,
                layoutWidth: action.payload,
            };
        case CHANGE_SIDEBAR_THEME:
            return {
                ...state,
                leftSideBarTheme: action.payload,
            };
        case CHANGE_SIDEBAR_TYPE:
            return {
                ...state,
                leftSideBarType: action.payload,
            };
        case TOGGLE_RIGHT_SIDEBAR:
            return {
                ...state,
                showRightSidebar: !state.showRightSidebar,
            };
        case SHOW_RIGHT_SIDEBAR:
            return {
                ...state,
                showRightSidebar: true,
                rightSidebarTitle: action.payload.name,
                rightSidebarChildren: action.payload.children
            };
        case HIDE_RIGHT_SIDEBAR:
            return {
                ...state,
                showRightSidebar: false,
                rightSidebarTitle: null,
                rightSidebarChildren: null
            };
        default:
            return state;
    }
};

export default Layout;
