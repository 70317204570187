/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

// @flow
import {
    CHANGE_LAYOUT,
    CHANGE_LAYOUT_WIDTH,
    CHANGE_SIDEBAR_THEME,
    CHANGE_SIDEBAR_TYPE,
    HIDE_RIGHT_SIDEBAR,
    SHOW_RIGHT_SIDEBAR,
    TOGGLE_RIGHT_SIDEBAR,
} from './constants';

type LayoutAction = { type: string, payload?: string | null };

export const changeLayout = (layout: string): LayoutAction => ({
    type: CHANGE_LAYOUT,
    payload: layout,
});

export const changeLayoutWidth = (width: string): LayoutAction => ({
    type: CHANGE_LAYOUT_WIDTH,
    payload: width,
});

export const changeSidebarTheme = (theme: string): LayoutAction => ({
    type: CHANGE_SIDEBAR_THEME,
    payload: theme,
});

export const changeSidebarType = (sidebarType: string): LayoutAction => ({
    type: CHANGE_SIDEBAR_TYPE,
    payload: sidebarType,
});

export const toggleRightSidebar = (): LayoutAction => ({
    type: TOGGLE_RIGHT_SIDEBAR,
    payload: null,
});

export const showRightSidebar = (content: { name: string, children: any }): LayoutAction => ({
    type: SHOW_RIGHT_SIDEBAR,
    payload: content,
});

export const hideRightSidebar = (): LayoutAction => ({
    type: HIDE_RIGHT_SIDEBAR,
    payload: null,
});
