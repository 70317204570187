/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';

import {Provider} from 'react-redux';
import {configureStore} from './redux/store';
import {ToastContainer} from 'react-toastify';
import {Integrations} from '@sentry/tracing';
import {API} from './constants/api';
import {getOrGoLogin} from './helpers/api';
import {setupVersionRefresh} from './VersionRefresh';
import { handleErrorAsConsole } from './helpers/errors';

setupVersionRefresh();
getOrGoLogin(API.APP_CONFIG.path)
    .then(response => {
        window.document.title = 'GI Prodserver - ' + response.data.site;
        Sentry.init({
            dsn: response.data.sentryEnabled ? response.data.sentryDsn : '',
            environment: response.data.environment,
            release: process.env.npm_package_version,
            integrations: [
                new Integrations.BrowserTracing(),
            ],
            sampleRate: 1.0,
            // Ignoring as the better solution for this at the moment.
            // ref: https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/3
            ignoreErrors: ['ResizeObserver loop limit exceeded'],
        });
        Sentry.setContext('Versions', {
            'Server': response.data.serverVersion,
        });
    })
    .catch(error => handleErrorAsConsole(error, 'Failed to initialise Sentry'))

ReactDOM.render(
    <Provider store={configureStore()}>
        <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
        />
        <App/>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
