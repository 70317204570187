/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

// @flow
import MenuItem from './models';

/**
 * Flatten the nested list of menu items
 */
let uuid = 1;

const assignIdAndParent = (menuItems: Array<MenuItem>, parentId?: number) => {
    menuItems = menuItems || [];
    menuItems.forEach(item => {
        const id = item.id || uuid;
        uuid += 1;
        item.id = id;
        item.parentId = item.parentId || parentId;
        item.active = false;

        if (typeof item.children !== 'undefined') {
            assignIdAndParent(item.children, id);
        }
    });
};

const menuItems = (menuItems: Array<MenuItem>) => {
    assignIdAndParent(menuItems);
    return menuItems;
};

export default menuItems;
