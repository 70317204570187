/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
import {all, call, fork, put, takeEvery, select} from 'redux-saga/effects';

import {CHANGE_ACTIVE_MENU_FROM_LOCATION, INIT_MENU} from './constants';
import {appRoutes as routes} from '../../routes';
import {getUser} from'../user/saga';
import assignIds from './utils';
import {changeActiveMenuFromLocationSuccess, initMenuFailed, initMenuSuccess} from './actions';
import {handleErrorAsToast} from '../../helpers/errors';

/**
 * Activate menu items from location
 * @param {*} menuItems
 */
const getActivatedMenuItemIds = menuItems => {
    var matchingItems = [];
    for (var menuItem of menuItems) {
        if (window.location.pathname.indexOf(menuItem.path) === 0) {
            matchingItems.push(menuItem.id);
        }

        if (typeof menuItem.children !== 'undefined') {
            matchingItems = [...matchingItems, ...getActivatedMenuItemIds(menuItem.children)];
        }
    }
    return matchingItems;
};

/**
 * Initilizes the menu
 */
function* initMenuAndItems() {
    try {
        const user = yield select(getUser)
        let userMenuItems = routes.filter(route => {
            return !route.access || (user && user.authorities.some(authority => route.access === authority));
        });

        const menuItems = assignIds(userMenuItems);
        yield put(initMenuSuccess(menuItems));
    } catch (error) {
        handleErrorAsToast(error, 'Failed to load sidebar menu');
        yield put(initMenuFailed(error.statusText));
    }
}

/**
 * change the active menu item based on location
 */
function* changeActiveMenuFromLocation() {
    try {
        const menuItems = assignIds(routes);
        const activatedMenuItemIds = yield call(getActivatedMenuItemIds, menuItems);
        yield put(changeActiveMenuFromLocationSuccess(activatedMenuItemIds));
    } catch (error) {
        // Do nothing
    }
}

/**
 * Watchers
 */
export function* watchMenuInit(): any {
    yield takeEvery(INIT_MENU, initMenuAndItems);
}

export function* watchMenuChange(): any {
    yield takeEvery(CHANGE_ACTIVE_MENU_FROM_LOCATION, changeActiveMenuFromLocation);
}

function* appMenuSaga(): any {
    yield all([fork(watchMenuInit), fork(watchMenuChange)]);
}

export default appMenuSaga;
