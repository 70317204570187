/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2020] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
import {
    INIT_USER_INFO,
    INIT_USER_INFO_FAILED,
    INIT_USER_INFO_SUCCESS,
    USER_SETTINGS,
    USER_SETTINGS_FAILED,
    USER_SETTINGS_SUCCESS
} from './constants';
import {State, UserAction} from './types';

const INIT_STATE = {
    error: null,
    message: null
};

const User = (state: State = INIT_STATE, action: UserAction) => {
    switch (action.type) {
        case INIT_USER_INFO:
            return action.payload;
        case INIT_USER_INFO_SUCCESS:
            return action.payload;
        case INIT_USER_INFO_FAILED:
            return {...state, error: action.payload};
        case USER_SETTINGS:
            return {...state, loading: true};
        case USER_SETTINGS_SUCCESS:
            return {...state, message: action.payload, error: null};
        case USER_SETTINGS_FAILED:
            return {...state, error: action.payload, message: null};
        default:
            return {...state};
    }
};

export default User;
