/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
import {Role} from '../constants/api';
import {UserType} from '../redux/user/types';

export const LOCAL_STORAGE_AUTH_KEY = 'GI_FA.authenticated';

/**
 * Checks if user is authenticated
 */
export const isUserAuthenticated = () => {
    return localStorage.getItem(LOCAL_STORAGE_AUTH_KEY) === 'true';
};

export const setAuthenticated = (value: boolean): void => {
    localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, JSON.stringify(value));
}

/**
 * Convenience method to get the initials of a particular user.
 * TODO: Remove this and replace with a profile icon.
 */
export const getInitials = (user: UserType): string => {
    return user ? user.fullName.substring(0, 1).toUpperCase() : '';
};

const userHasAuthority = (user: UserType, authority: string): boolean => {
    return user.authorities.includes(authority)
}

export const userIsAdmin = (user: UserType) => userHasAuthority(user, Role.ADMIN)
export const userIsTeamLeader = (user: UserType) => userHasAuthority(user, Role.TEAM_LEADER)
export const userIsOperator = (user: UserType) => userHasAuthority(user, Role.OPERATOR)
