/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {
    INIT_STATION_INFO,
    INIT_STATION_INFO_FAILED,
    INIT_STATION_INFO_SUCCESS,
} from './constants';
import {State, StationAction} from './types';

const INIT_STATE = {
    error: null,
    message: null
};

const Station = (state: State = INIT_STATE, action: StationAction) => {
    switch (action.type) {
        case INIT_STATION_INFO:
            return action.payload;
        case INIT_STATION_INFO_SUCCESS:
            return action.payload;
        case INIT_STATION_INFO_FAILED:
            return {...state, error: action.payload};
        default:
            return {...state};
    }
};

export default Station;
