/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2020] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import {getOrGoLogin} from '../../helpers/api';

import {API} from '../../constants/api';
import {initUserInfoSuccess, userInfoFailed, userSettingsFailed, userSettingsSuccess} from './actions';
import {UserSettingsType, UserType} from './types';
import {INIT_USER_INFO, USER_SETTINGS} from './constants';
import { handleErrorAsToast } from '../../helpers/errors';

export const getUser = (state: any) => state.User.data

function* userSettings({payload: {password, new_password, password_confirm}}: UserSettingsType) {
    const options = {
        method: 'POST',
        data: {password, new_password, password_confirm}
    };

    try {
        const response: string = yield call(getOrGoLogin, API.USER.SAVE_SETTINGS.path, options);
        yield put(userSettingsSuccess(response));
    } catch (error: any) {
        handleErrorAsToast(error, 'Failed to save user info');
        yield put(userSettingsFailed(error.statusText));
    }
}

function* initUserInfo() {
    try {
        const response: UserType = yield call(getOrGoLogin, API.GET_PROFILE_INFO.path);
        yield put(initUserInfoSuccess(response));
    } catch (error: any) {
        handleErrorAsToast(error, 'Failed to fetch user info');
        yield put(userInfoFailed(error.statusText));
    }
}

export function* watchUserSettings(): any {
    // @ts-ignore
    yield takeEvery(USER_SETTINGS, userSettings);
}

export function* watchUserInfoInit(): any {
    // @ts-ignore
    yield takeEvery(INIT_USER_INFO, initUserInfo);
}

function* userSaga(): any {
    yield all([fork(watchUserSettings), fork(watchUserInfoInit)]);
}

export default userSaga;
