/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2021] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {Col, Row} from 'reactstrap';
import errorImg from '../../assets/images/file-searching.svg';
import {handleError} from '../../helpers/errors';

interface ErrorPageProps extends RouteComponentProps<{}, {}, ErrorPageState> {
    error: any,
    message?: string,
}

interface ErrorPageState {
    error: any,
    errorText?: string,
    message?: string,
    didRedirect: boolean,
}

class ErrorPage extends Component<ErrorPageProps, ErrorPageState> {
    constructor(props: Readonly<ErrorPageProps>) {
        super(props);

        this.state = {
            error: this.props.location.state?.error ?? this.props.error,
            message: this.props.location.state?.message ?? this.props.message,
            didRedirect: this.props.location.state?.didRedirect ?? false,
        }
    }

    componentDidMount() {
        this.getErrorText()
    }

    componentDidUpdate(prevProps: ErrorPageProps, prevState: ErrorPageState) {
        if (this.props !== prevProps) this.setState({error: this.props.error, message: this.props.message})
        if (this.state.error !== prevState.error) this.getErrorText()
    }

    getErrorText = () =>
        handleError(this.state.error, (errorText: string) => this.setState({errorText: errorText}))

    render() {
        return <Row className='justify-content-center'>
            <Col lg={4}>
                <div className='text-center mt-5'>
                    <img src={errorImg} height='90' alt=''/>
                    <h1 className='text-uppercase text-info mt-3'>{this.state.error?.status ?? ''}</h1>
                    <p className='text-dark mt-1 font-20'>{this.state.errorText ?? ''}</p>
                    <p className='text-dark'>{this.state.message ?? 'Something went wrong'}.</p>
                </div>

                <div className='d-flex justify-content-center mt-3'>
                    <button className='btn btn-secondary' onClick={this.props.history.goBack}>Go Back</button>
                    {!this.state.didRedirect && 
                    <button className='btn btn-primary ml-1' onClick={() => window.location.reload()}>Reload</button>}
                </div>
            </Col>
        </Row>;
    }
}

export default withRouter(ErrorPage);
