/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
import React, {Component} from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';
import {connect} from 'react-redux';

import {allFlattenRoutes as routes} from './index';
// All layouts/containers
import AuthLayout from '../layouts/Auth';
import VerticalLayout from '../layouts/Vertical';
import TerminalLayout from '../layouts/Terminal';
import {isUserAuthenticated, userIsOperator} from '../helpers/authUtils';

class Routes extends Component {
    // Returns the layout that should be used currently.
    getLayout = () => {
        if (!this.props.user || !isUserAuthenticated()) return AuthLayout;

        if (userIsOperator(this.props.user)) {
            if (!this.props.station) return AuthLayout
            return TerminalLayout
        } else {
            return VerticalLayout
        }
    };

    shouldComponentUpdate(nextProps) {
        let oldLayout = {...this.props.layout};
        delete oldLayout.showRightSidebar;
        let newLayout = {...nextProps.layout};
        delete newLayout.showRightSidebar;
        return (
            JSON.stringify(oldLayout) !== JSON.stringify(newLayout) ||
            JSON.stringify(this.props.auth) !== JSON.stringify(nextProps.auth) ||
            JSON.stringify(this.props.user) !== JSON.stringify(nextProps.user) ||
            JSON.stringify(this.props.station) !== JSON.stringify(nextProps.station)
        );
    }

    render() {
        const Layout = this.getLayout();
        // Render the router with the layout that should be used.
        return (
            <BrowserRouter>
                <Layout {...this.props}>
                    <Switch>
                        {routes.map((route, index) => {
                            return (
                                !route.children && (
                                    <route.route
                                        key={index}
                                        path={route.path}
                                        name={route.name}
                                        access={route.access}
                                        user={this.props.user}
                                        station={this.props.station}
                                        exact={route.exact}
                                        component={route.component}></route.route>
                                )
                            );
                        })}
                    </Switch>
                </Layout>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = state => {
    return {
        layout: state.Layout,
        user: state.User.data,
        station: state.Station.data,
        auth: state.Auth
    };
};

export default connect(
    mapStateToProps,
    null
)(Routes);
