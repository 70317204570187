/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {pageRoute, PAGES} from '../constants/pages';
import {getToken} from '../helpers/stationUtils';

import {PrivateRoute} from './PrivateRoute';
import {TerminalRoute} from './TerminalRoute';
import {TerminalPrivateRoute} from './TerminalPrivateRoute';

// Lazy load all the views.
const InitialiseUser= React.lazy(() => import('../pages/InitialiseUser'));

const ErrorPage = React.lazy(() => import('../pages/error/ErrorPage'));
const NotFound = React.lazy(() => import('../pages/error/NotFound'));

const Jobs = React.lazy(() => import('../pages/manager/apps/jobs/Jobs'));
const Reworks = React.lazy(() => import('../pages/manager/apps/reworks/Reworks'))
const Staff = React.lazy(() => import('../pages/manager/apps/staff/Staff'));
const Stations = React.lazy(() => import('../pages/manager/apps/stations/Stations'));
const Timesheets = React.lazy(() => import('../pages/manager/apps/timesheets/Timesheets'));
const Login = React.lazy(() => import('../pages/manager/auth/Login'));
const Logout = React.lazy(() => import('../pages/manager/auth/Logout'));

const InitialiseTerminal = React.lazy(() => import('../pages/terminal/InitialiseTerminal'));
const JobTasks = React.lazy(() => import('../pages/terminal/apps/jobs/JobTasks'));
const StaffLogin = React.lazy(() => import('../pages/terminal/auth/StaffLogin'));
const StaffLogout = React.lazy(() => import('../pages/terminal/auth/StaffLogout'));

const rootRoute = {
    path: '/',
    exact: true,
    component: () => {
        if (getToken()) {
            // Staff Home
            return <Redirect to={PAGES.TERMINAL.JOB_TASKS.path}/>
        }
        // Manager Home
        else return <Redirect to={PAGES.JOBS.path}/>
    },
    route: Route,
};

// apps
const appRoutes = [
    pageRoute(PAGES.JOBS, Jobs, PrivateRoute),
    pageRoute(PAGES.REWORKS, Reworks, PrivateRoute),
    pageRoute(PAGES.STAFF, Staff, PrivateRoute),
    pageRoute(PAGES.REVIEW, Timesheets, PrivateRoute),
    pageRoute(PAGES.STATIONS, Stations, PrivateRoute),
]

// auth
const authRoutes = [
    pageRoute(PAGES.LOGIN, Login, Route),
    pageRoute(PAGES.LOGOUT, Logout, Route),
]

// error
const errorRoutes = [
    pageRoute(PAGES.ERROR_PAGE, ErrorPage, PrivateRoute),
]

const notFoundRoute = pageRoute(PAGES, NotFound, PrivateRoute)

// user
const userRoutes = [
    pageRoute(PAGES.INITIALISE, InitialiseUser, PrivateRoute, true)
]

// terminal
const terminalRoutes = [
    pageRoute(PAGES.TERMINAL, InitialiseTerminal, Route, true),

    // auth
    pageRoute(PAGES.TERMINAL.LOGIN, StaffLogin, TerminalRoute),
    pageRoute(PAGES.TERMINAL.LOGOUT_SUMMARY, StaffLogout, TerminalRoute),

    // jobs
    pageRoute(PAGES.TERMINAL.JOB_TASKS, JobTasks, TerminalPrivateRoute),
]

// Flatten the list of all nested routes.
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

const allRoutes = [
    rootRoute,
    ...authRoutes,
    ...appRoutes,
    ...userRoutes,
    ...errorRoutes,
    ...terminalRoutes,

    // Not Found route matching any route last.
    notFoundRoute
];

const allFlattenRoutes = flattenRoutes(allRoutes);

export {allRoutes, appRoutes, allFlattenRoutes};
