/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {Redirect, Route} from 'react-router-dom';
import {PAGES} from '../constants/pages';
import React from 'react';

/**
 * Secures pages that require client to be assigned a station.
 */
export const TerminalRoute = ({component: Component, station, ...rest}) => (
    <Route
        {...rest}
        render={props => {
            if (!station || station.inactive) {
                // No station or inactive, redirect to terminal setup.
                return <Redirect to={{pathname: PAGES.TERMINAL.path, state: {from: props.location}}}/>;
            }

            // Active station, so return component.
            return <Component {...props} station={station}/>;
        }}
    />
);
