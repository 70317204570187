/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2020] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {
    INIT_USER_INFO,
    INIT_USER_INFO_FAILED,
    INIT_USER_INFO_SUCCESS,
    USER_SETTINGS,
    USER_SETTINGS_FAILED,
    USER_SETTINGS_SUCCESS
} from './constants';
import {UserAction, UserType} from './types';

export const userSettings = (password: string, new_password: string, password_confirm: string): UserAction => ({
    type: USER_SETTINGS,
    payload: {password, new_password, password_confirm},
});

export const userSettingsSuccess = (message: string): UserAction => ({
    type: USER_SETTINGS_SUCCESS,
    payload: {message},
});

export const userSettingsFailed = (error: string): UserAction => ({
    type: USER_SETTINGS_FAILED,
    payload: {error},
});

export const initUserInfo = (): UserAction => ({
    type: INIT_USER_INFO,
    payload: {},
});

export const initUserInfoSuccess = (user: UserType): UserAction => ({
    type: INIT_USER_INFO_SUCCESS,
    payload: {data: user},
});

export const userInfoFailed = (error: string): UserAction => ({
    type: INIT_USER_INFO_FAILED,
    payload: {error},
});
