/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2021] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {getOrGoLogin} from './helpers/api';
import packageJson from '../package.json';
import { handleErrorAsConsole } from './helpers/errors';

let versionWhenLoaded: string = '';
const CHECK_INTERVAL = 60 * 1000;

export const setupVersionRefresh = () => {
    if (!packageJson) {
        checkVersion();
        setInterval(checkVersion, CHECK_INTERVAL);
    }
}

const checkVersion = () => {
    getOrGoLogin('/version.json', undefined, false)
        .then((response: any) => {
            const clientServerVersion = response.version;
            if (versionWhenLoaded !== '' && versionWhenLoaded !== clientServerVersion) {
                // reload the page as this page may be different on the next version
                window.location.reload();
            }
            versionWhenLoaded = clientServerVersion;
        })
        .catch(error => handleErrorAsConsole(error, 'Failed to check version'))
}
