/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import {postOrGoLogin} from '../../helpers/api';

import {LOGIN_USER, LOGOUT_STAFF_USER, LOGOUT_USER} from './constants';
import {API} from '../../constants/api';

import {loginUserFailed, loginUserSuccess,} from './actions';
import {setAuthenticated} from '../../helpers/authUtils';
import { handleErrorAsToast } from '../../helpers/errors';

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({payload: {login, password}}) {
    try {
        const response = yield call(postOrGoLogin, API.LOGIN.path, {login, password});
        setAuthenticated(true);
        yield put(loginUserSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 400:
                message = error.statusText;
                break;
            default:
                message = error;
        }
        yield put(loginUserFailed(message));
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout() {
    try {
        setAuthenticated(false);
        yield call(() => {
            if (window.location.hostname === 'localhost') {
                window.location.href = `http://localhost:8080${API.LOGOUT.path}`
            } else {
                window.location.href = API.LOGOUT.path;
            }
        });
    } catch (error) {
        handleErrorAsToast(error, 'Cannot logout')
    }
}

/**
 * Logout the staff user
 */
function* logoutStaff({payload: isClockOut}) {
    try {
        setAuthenticated(false);
        yield call(() => {
            if (window.location.hostname === 'localhost') {
                window.location.href = `http://localhost:8080${API.STAFF_LOGOUT.path}?isClockOut=${isClockOut}`
            } else {
                window.location.href = API.STAFF_LOGOUT.path + '?isClockOut=' + isClockOut;
            }
        });
    } catch (error) {
        // Do nothing
    }
}

export function* watchLoginUser(): any {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser(): any {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchLogoutStaffUser(): any {
    yield takeEvery(LOGOUT_STAFF_USER, logoutStaff);
}

function* authSaga(): any {
    yield all([fork(watchLoginUser), fork(watchLogoutUser), fork(watchLogoutStaffUser)]);
}

export default authSaga;
